import {useEffect, useState} from 'react';
import {useLocation, Link} from 'react-router-dom';
import {Navbar} from 'react-bootstrap';

import logo from 'assets/img/logo.png';
import logoMini from 'assets/img/logo-mini.png';
// import fbIcon from 'assets/img/facebook-icon.svg';
import xIcon from 'assets/img/x-icon.svg';
import tgIcon from 'assets/img/telegram-icon.svg';
import igIcon from 'assets/img/instagram-icon.svg';
// import inIcon from 'assets/img/linkedin-icon.svg';
import ytIcon from 'assets/img/youtube-icon.svg';

const Header = ({isSmScreen}) => {
    const [scroll, setScroll] = useState(false);
    const {pathname} = useLocation();

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY > 20);
        });
    }, [scroll]);

    const handleTogglerClick = () => {
        document.querySelector('.navbar').classList.toggle('collapsed');
        document.querySelector('body').classList.toggle('navbar-opened');
    }

    const handleNavbarClose = () => {
        if (window.innerWidth <= 991) {
            document.querySelector('header .navbar .navbar-toggler').click();
            document.querySelector('body').classList.remove('navbar-opened');
        }
    }

    return (
        <header className={`${scroll ? 'scroll' : ''}`}>
            <Navbar expand="xl">
                <Link className="navbar-brand" to="/">
                    {isSmScreen
                        ? <img src={logoMini} alt="Logo"/>
                        : <img src={logo} alt="Logo"/>
                    }
                </Link>
                <Navbar.Toggle onClick={handleTogglerClick} aria-controls="navbar-nav-dropdown">
                    <div className="text-wrap">
                        <p>Menu</p>
                    </div>
                    <div className="icons-wrap">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </div>
                </Navbar.Toggle>
                <Navbar.Collapse id="navbar-nav-dropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to="/"
                                  className={`${pathname === '/home' ? 'nav-link active' : 'nav-link'}`}
                                  onClick={handleNavbarClose}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <a href="/solutions"
                               className={`${pathname === '/solutions' ? 'nav-link active' : 'nav-link'}`}
                               onClick={handleNavbarClose}>AI Solutions</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://produceai.gitbook.io/docs/" target="_blank" rel="noreferrer"
                               className={`${pathname === '/whitepaper' ? 'nav-link active' : 'nav-link'}`}
                               onClick={handleNavbarClose}>Whitepaper</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://app.produceai.io/" target="_blank" rel="noreferrer"
                               className={`${pathname === '/dashboard' ? 'nav-link active' : 'nav-link'}`}
                               onClick={handleNavbarClose}>Investor Dashboard</a>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <Link to="/"*/}
                        {/*          className={`${pathname === '/staking' ? 'nav-link active' : 'nav-link'}`}*/}
                        {/*          onClick={handleNavbarClose}>Staking</Link>*/}
                        {/*</li>*/}
                    </ul>
                    <div className="navbar-socials">
                        {/*<a href="/">*/}
                        {/*    <img src={fbIcon} alt="Facebook"/>*/}
                        {/*</a>*/}
                        <a href="https://twitter.com/PRAIERC" target="_blank" rel="noreferrer">
                            <img src={xIcon} alt="X"/>
                        </a>
                        <a href="https://t.me/ProduceAiOfficial" target="_blank" rel="noreferrer">
                            <img src={tgIcon} alt="X"/>
                        </a>
                        <a href="https://www.instagram.com/ProduceAIToken" target="_blank" rel="noreferrer">
                            <img src={igIcon} alt="Instagram"/>
                        </a>
                        {/*<a href="/">*/}
                        {/*    <img src={inIcon} alt="LinkedIn"/>*/}
                        {/*</a>*/}
                        <a href="https://www.youtube.com/@ProduceAIToken" target="_blank" rel="noreferrer">
                            <img src={ytIcon} alt="YouTube"/>
                        </a>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
}

export default Header;
