import React from 'react';
import {useMediaQuery} from 'react-responsive';
import {Col, Container, Row} from "react-bootstrap";

import ScrollToTopOnMount from 'components/General/ScrollToTopOnMount';
import Header from 'components/General/Header';
import Footer from 'components/General/Footer';

import heroImg from "assets/img/solutions-hero-img.png";
import heroMobileImg from "assets/img/solutions-hero-mobile-img.png";
import itemsOne from "assets/img/solutions-items-item-1.png";
import itemsTwo from "assets/img/solutions-items-item-2.png";
import itemsThree from "assets/img/solutions-items-item-3.png";

const Solutions = () => {
    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 767px)'});

    return (
        <>
            <ScrollToTopOnMount/>
            <Header isSmScreen={isSmScreen}/>
            <main>
                <section id="solutions-hero" className="solutions-hero-section">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="title-wrap">
                                    <h2>Ai Solutions</h2>
                                    {isSmScreen
                                        ? <img src={heroMobileImg} alt="Solutions"/>
                                        : <img src={heroImg} alt="Solutions"/>
                                    }
                                </div>
                                <div className="items-wrap">
                                    <div className="item">
                                        <div className="text-wrap">
                                            <h3>Supply Chain Management</h3>
                                            <p>We embrace a smarter supply chain with AI-driven solutions that optimize
                                                logistics, inventory management, and distribution. Our AI algorithms
                                                analyze historical data, real-time demand signals, and external factors
                                                to streamline the supply chain, minimizing costs, reducing delays, and
                                                maximizing overall productivity.</p>
                                        </div>
                                        <div className="img-wrap">
                                            <img src={itemsOne} alt="Item 1"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="text-wrap">
                                            <h3>Forecasting</h3>
                                            <p>Our AI forecasting tools leverage the power of predictive analytics to
                                                anticipate market trends, demand fluctuations, and seasonal variations.
                                                By analyzing vast datasets, our AI models provide accurate forecasts,
                                                enabling our company to make informed decisions, optimize production
                                                schedules, and maintain optimal inventory levels</p>
                                        </div>
                                        <div className="img-wrap">
                                            <img src={itemsTwo} alt="Item 2"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="text-wrap">
                                            <h3>Quality<br/> Control</h3>
                                            <p>We are elevating the standard of produce with AI-powered quality control
                                                mechanisms. Our computer vision technology ensures meticulous inspection
                                                of every product, identifying defects, anomalies, and inconsistencies
                                                with precision. This not only guarantees superior product quality but
                                                also minimizes waste by early detection of potential issues.</p>
                                        </div>
                                        <div className="img-wrap">
                                            <img src={itemsThree} alt="Item 3"/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer isSmScreen={isSmScreen}/>
        </>
    )
}

export default Solutions;
