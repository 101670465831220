import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import './App.scss';

import Main from './components/Main';
import Solutions from './components/Solutions';

const App = () => {
    const appHeightHandle = () => {
        document.querySelector('body').style.setProperty('--app-height', window.innerHeight + 'px');
    }

    appHeightHandle();

    window.addEventListener('resize', () => {
        appHeightHandle();
    });

    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/" component={Main}/>
                    <Route exact path="/solutions" component={Solutions}/>
                    {/*<Route exact path="/developers" component={Developers}/>
                    <Route exact path="/influencers" component={Influencers}/>
                    <Route exact path="/referral" component={Referral}/>
                    <Route component={NotFound}/>*/}
                </Switch>
            </Router>
        </>
    )
};

export default App;
