import React from 'react';
import {useMediaQuery} from 'react-responsive';
import {Col, Container, Row} from "react-bootstrap";

import ScrollToTopOnMount from 'components/General/ScrollToTopOnMount';
import Header from 'components/General/Header';
import Footer from 'components/General/Footer';

import heroBg from "assets/img/main-hero-bg.png";
import heroBtn from "assets/img/btn-watch-img.svg";
import heroScrollIcon from "assets/img/scroll-down-icon.svg";
import video from "assets/img/process.mp4";

import aboutImg from "assets/img/main-about-img.png";
import aboutCircleIcon from "assets/img/main-about-circle-icon.svg";

import tokenomicsBg from "assets/img/main-tokenomics-bg.png";
import tokenomicsItemBg from "assets/img/main-tokenomics-item-bg.svg";

// import fbIcon from 'assets/img/facebook-white-icon.svg';
import xIcon from 'assets/img/x-white-icon.svg';
import igIcon from 'assets/img/instagram-white-icon.svg';
import tgIcon from 'assets/img/telegram-white-icon.svg';
// import inIcon from 'assets/img/linkedin-white-icon.svg';
import ytIcon from 'assets/img/youtube-white-icon.svg';

import followImg from "assets/img/main-follow-img.png";

const Main = () => {
    // const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 767px)'});

    return (
        <>
            <ScrollToTopOnMount/>
            <Header isSmScreen={isSmScreen}/>
            <main>
                <section id="main-hero" className="main-hero-section">
                    <div className="bg-wrap">
                        <img src={heroBg} alt="Bg"/>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="title-wrap">
                                    <h1>
                                        <span>AI Built</span>
                                        <span>for</span>
                                        <span className="gradient-text">Produce</span>
                                    </h1>
                                    <div className="scroll-wrap">
                                        <img src={heroScrollIcon} alt="Scroll"/>
                                    </div>
                                </div>
                                <div className="text-wrap">
                                    <p><span>{`//`}</span> Produce Ai offers cutting-edge Al<br/> technologies to enhance
                                        efficiency, reduce<br/> waste, and deliver high-quality produce.</p>
                                    <a href={video} target="_blank" rel="noreferrer">
                                        <img src={heroBtn} alt="Watch video about our process"/>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="main-about" className="main-about-section">
                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="img-wrap">
                                    <img src={aboutImg} alt="About"/>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="text-wrap">
                                    <h2>
                                        <span>AI gives us</span>
                                        <span className="gradient-text">our edge</span>
                                    </h2>
                                    <p>
                                        <span className="icon">
                                            <img src={aboutCircleIcon} alt="Icon"/>
                                        </span>
                                        <span className="text">
                                            Produce Al optimize’s the supply chain through predictive analytics, reducing waste and improving quality control with computer vision, automating tasks to enhance efficiency and cut costs. The data-driven decision-making facilitated by Al leads to informed choices and adaptability to market trends, while sustainability initiatives, such as minimizing food waste and optimizing resource allocation, align with modern environmental concerns.
                                        </span>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="main-tokenomics" className="main-tokenomics-section">
                    <div className="bg-wrap">
                        <img src={tokenomicsBg} alt="Bg"/>
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="title-wrap">
                                    <h2>Taxes</h2>
                                </div>
                                <div className="items-wrap">
                                    <div className="item">
                                        <div className="bg">
                                            <img src={tokenomicsItemBg} alt="Item Bg"/>
                                        </div>
                                        <div className="text">
                                            <h3 className="gradient-text">4%</h3>
                                            <p>Operating Fund</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="bg">
                                            <img src={tokenomicsItemBg} alt="Item Bg"/>
                                        </div>
                                        <div className="text">
                                            <h3 className="gradient-text">1%</h3>
                                            <p>Development Fund</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="bg">
                                            <img src={tokenomicsItemBg} alt="Item Bg"/>
                                        </div>
                                        <div className="text">
                                            <h3 className="gradient-text">100M</h3>
                                            <p>Total Supply</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="main-follow" className="main-follow-section">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="img-wrap">
                                    <img src={followImg} alt="Follow"/>
                                </div>
                                <div className="text-wrap">
                                    <h2>
                                        <span>Follow us</span>
                                        <span className="gradient-text">on socials</span>
                                    </h2>
                                </div>
                                <div className="actions-wrap">
                                    <div className="socials-wrap">
                                        <ul>
                                            {/*<li>*/}
                                            {/*    <Link to="/">*/}
                                            {/*        <img src={fbIcon} alt="Facebook"/>*/}
                                            {/*    </Link>*/}
                                            {/*</li>*/}
                                            <li>
                                                <a href="https://twitter.com/PRAIERC" target="_blank"
                                                   rel="noreferrer">
                                                    <img src={xIcon} alt="X"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://t.me/ProduceAiOfficial" target="_blank"
                                                   rel="noreferrer">
                                                    <img src={tgIcon} alt="Telegram"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/ProduceAIToken" target="_blank"
                                                   rel="noreferrer">
                                                    <img src={igIcon} alt="Instagram"/>
                                                </a>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <Link to="/">*/}
                                            {/*        <img src={inIcon} alt="LinkedIn"/>*/}
                                            {/*    </Link>*/}
                                            {/*</li>*/}
                                            <li>
                                                <a href="https://www.youtube.com/@ProduceAIToken" target="_blank"
                                                   rel="noreferrer">
                                                    <img src={ytIcon} alt="YouTube"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="btn-wrap">
                                        <a href={video} target="_blank" rel="noreferrer">
                                            <img src={heroBtn} alt="Watch video about our process"/>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer isSmScreen={isSmScreen}/>
        </>
    )
}

export default Main;
