import {Container, Row, Col} from "react-bootstrap";
import {Link} from 'react-router-dom';

import logo from 'assets/img/logo.png';
import logoMini from 'assets/img/logo-mini.png';
// import fbIcon from 'assets/img/facebook-icon.svg';
import xIcon from 'assets/img/x-icon.svg';
import tgIcon from 'assets/img/telegram-icon.svg';
import igIcon from 'assets/img/instagram-icon.svg';
// import inIcon from 'assets/img/linkedin-icon.svg';
import ytIcon from 'assets/img/youtube-icon.svg';

const Footer = ({isSmScreen}) => {
    return (
        <footer>
            <Container>
                <Row>
                    <Col xs={12} lg={6}>
                        <div className="links-wrap">
                            <div className="logo-wrap">
                                {isSmScreen
                                    ? <img src={logoMini} alt="Logo"/>
                                    : <img src={logo} alt="Logo"/>
                                }
                            </div>
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/solutions">AI Solutions</Link>
                                </li>
                                <li>
                                    <a href="https://produceai.gitbook.io/docs/" target="_blank"
                                       rel="noreferrer">Whitepaper</a>
                                </li>
                                <li>
                                    <a href="https://app.produceai.io/" target="_blank" rel="noreferrer">
                                        Investor Dashboard
                                    </a>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to="/">Staking</Link>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className="socials-wrap">
                            <ul>
                                {/*<li>*/}
                                {/*    <Link to="/">*/}
                                {/*        <img src={fbIcon} alt="Facebook"/>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li>
                                    <a href="https://twitter.com/PRAIERC" target="_blank" rel="noreferrer">
                                        <img src={xIcon} alt="X"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://t.me/ProduceAiOfficial" target="_blank" rel="noreferrer">
                                        <img src={tgIcon} alt="X"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/ProduceAIToken" target="_blank"
                                       rel="noreferrer">
                                        <img src={igIcon} alt="Instagram"/>
                                    </a>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to="/">*/}
                                {/*        <img src={inIcon} alt="LinkedIn"/>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li>
                                    <a href="https://www.youtube.com/@ProduceAIToken" target="_blank" rel="noreferrer">
                                        <img src={ytIcon} alt="YouTube"/>
                                    </a>
                                </li>
                            </ul>
                            <div className="copyright-wrap">
                                <p>2024</p>
                                <p>All rights Reserved</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="address-wrap">
                <p>CA: 0x0e6D98de664A28a32B584c72E606Ff7DeA898Dc6</p>
                <p>Email: <a href="mailto:info@produceai.io">info@produceai.io</a></p>
            </div>
        </footer>
    );
}

export default Footer;
